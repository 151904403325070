import React, { useState } from 'react';
import './observations.scss';

// interface Finding {
//     srlNo: string;
//     observations: string;
//     type: string;
//     assignedBy: string;
//     assignedTo: string;
//     status: string;
//     comments: string;
//     date: string;
//   }

interface ObservationsProps{
    isOpen: boolean;
    onClose: ()=> void;
    content:  string;
    //index: number;
   // srlNo:string;
}

const Observations: React.FC<ObservationsProps> = ({isOpen,onClose,content}) =>{
    const [selectedItem, setSelectedItem] = useState<number|null>(null);
    const IndividualObservations = (index:number)=>{
    setSelectedItem(selectedItem===index? null:index)
    }
    if(!isOpen){
        return(null)
    }
    return(
        <div className='observations-overlay'>
            <div className='observations-content'>
                <div className='observation-close-button' onClick={onClose}>
                    &times;
                </div>
                
                <div className='observations-text'>{content}</div>
        
            </div>
        </div>
    );
};

export default Observations;