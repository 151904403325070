import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import axios from "axios";
import { VoidExpression } from "typescript";

export const assessmentSlice = createSlice({
    name: "assessment",
    initialState: {
        latestAssessment: []
    },
    reducers: {
        updateLatestAssessment: (state, action) => {
            state.latestAssessment = action.payload;
        },
    }
})

export const { updateLatestAssessment } = assessmentSlice.actions;
export default assessmentSlice.reducer;

export function getLatestAssessment(projectid: string): (getState: any, dispatch: any) => Promise<void> {
    return async function getLatestAssessmentThunk(getState, dispatch) {
        try {
            const res = await axios.get(
                `http://localhost:5000/api/latest-assessment/${projectid}`
            );
            dispatch(updateLatestAssessment(res.data))
        } catch (error) {
            console.log(error)
        }
    }
}