import { configureStore } from '@reduxjs/toolkit'
import globalVariableReducer from "./slices/globalVariableSlice"
import findingVariableReducer from "../redux/slices/findingSlice"
import projectReducer from "../redux/slices/archiveSlice"
import assessmentReducer from './slices/assessmentSlice'

export const store = configureStore({
    reducer: {
        globalVariable: globalVariableReducer,
        assessment: assessmentReducer,
        findingVariable: findingVariableReducer,
        projects: projectReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch