import React, { useEffect, useState, useRef, useCallback } from "react";
import "./DropDown.scss";
import ReactDOM from "react-dom";

interface DropDownPopupProps {
  onSave: (dropItems: { response: string }[]) => void;
  savedItems: { response: string }[];
  onClose: () => void;
  inputType: string;
  rowEdit: { response: string }[];
}

const DropDownPopup: React.FC<DropDownPopupProps> = ({
  onSave,
  savedItems,
  onClose,
  inputType,
  rowEdit,
}) => {
  // for drop down
  const [dropItems, setDropItems] = useState<{ response: string }[]>([]);
  const [newDropValue, setNewDropValue] = useState<string>("");
  const [errVar, setErrVar] = useState<boolean>(false);

  // for check box
  const [boxItems, setBoxItems] = useState<{ response: string }[]>([]);
  const [newBoxValue, setNewBoxValue] = useState<string>("");

  // for radio
  const [radioItems, setRadioItems] = useState<{ response: string }[]>([]);
  const [newRadioValue, setNewRadioValue] = useState<string>("");

  useEffect(() => {
    if (inputType == "dropdown") {
      setDropItems(savedItems);
      setBoxItems([]);
      setRadioItems([]);
    } else if (inputType == "checkbox") {
      setDropItems([]);
      setBoxItems(savedItems);
      setRadioItems([]);
    } else {
      setDropItems([]);
      setBoxItems([]);
      setRadioItems(savedItems);
    }
  }, [inputType]);

  // Drop Down functions
  const dropAddItem = () => {
    if (newDropValue.trim() !== "") {
      setDropItems([...dropItems, { response: newDropValue }]);
      setNewDropValue("");
    }
  };
  const dropSaveAndClose = () => {
    onSave(dropItems);
  };
  const dropRemoveItem = (index: number) => {
    const updatedItems = [...dropItems];
    updatedItems.splice(index, 1);
    setDropItems(updatedItems);
  };
  const onChangeDropval = (val: any) => {
    setNewDropValue(val);
    if (
      dropItems.find(
        (item) =>
          item.response.trim().toLowerCase() === val.trim().toLowerCase()
      )
    ) {
      setErrVar(true);
    } else {
      setErrVar(false);
    }
  };

  // Check Box functions
  const boxAddItem = () => {
    if (newBoxValue.trim() !== "") {
      setBoxItems([...boxItems, { response: newBoxValue }]);
      setNewBoxValue("");
    }
  };
  const boxSaveAndClose = () => {
    onSave(boxItems);
  };
  const boxRemoveItem = (index: number) => {
    const updatedItems = [...boxItems];
    updatedItems.splice(index, 1);
    setBoxItems(updatedItems);
  };
  const onChangeBoxval = (val: any) => {
    setNewBoxValue(val);
    if (
      boxItems.find(
        (item) =>
          item.response.trim().toLowerCase() === val.trim().toLowerCase()
      )
    ) {
      setErrVar(true);
    } else {
      setErrVar(false);
    }
  };

  // Radio button function
  const radioAddItem = () => {
    if (newRadioValue.trim() !== "") {
      setRadioItems([...radioItems, { response: newRadioValue }]);
      setNewRadioValue("");
    }
  };
  const radioSaveAndClose = () => {
    onSave(radioItems);
  };
  const radioRemoveItem = (index: number) => {
    const updatedItems = [...radioItems];
    updatedItems.splice(index, 1);
    setRadioItems(updatedItems);
  };
  const onChangeRadioval = (val: any) => {
    setNewRadioValue(val);
    if (
      radioItems.find(
        (item) =>
          item.response.trim().toLowerCase() === val.trim().toLowerCase()
      )
    ) {
      setErrVar(true);
    } else {
      setErrVar(false);
    }
  };

  return ReactDOM.createPortal(
    <div className="main">
      <div className="popup">
        <div className="popup-content">
          {/* dropdown */}
          {inputType == "dropdown" && (
            <>
              <div className="header">
                <span>Create Drop Down Options</span>
                <span className="app-closebutton" onClick={onClose}>
                  {" "}
                  <i className="fa-solid fa-xmark"></i>{" "}
                </span>
              </div>
              <div>
                {dropItems.map((item, index) => (
                  <div className="dropdownitems" key={index}>
                    <span>
                      <input
                        disabled
                        value={item.response}
                        type="Text"
                        className="textopt"
                      />
                    </span>
                    <span onClick={() => dropRemoveItem(index)}>
                      <img
                        slot="end"
                        className="crossImg"
                        src={require("../../../../images/cross.png")}
                        alt=""
                      />
                    </span>
                  </div>
                ))}
                <br />
                <div className="dropdownitems1">
                  <span>
                    <input
                      type="text"
                      autoFocus
                      className="boxradiofield"
                      value={newDropValue}
                      onChange={(e) => onChangeDropval(e.target.value)}
                      placeholder="Enter your drop down option here."
                    />
                  </span>
                  {errVar && (
                    <span className="errorCode">
                      Please enter unique options.
                    </span>
                  )}
                </div>
              </div>
              <br />
              <br />
              <span className="addbutton">
                <button onClick={dropAddItem} className="add" disabled={errVar}>
                  Add+
                </button>
              </span>
              <span className="savebutton">
                <button
                  onClick={dropSaveAndClose}
                  className="save"
                  disabled={dropItems.length < 1}
                >
                  Save
                </button>
              </span>
            </>
          )}

          {/* checkbox */}
          {inputType == "checkbox" && (
            <>
              <div className="header">
                <span>Create Check Box Options</span>
                <span className="app-closebutton" onClick={onClose}>
                  {" "}
                  <i className="fa-solid fa-xmark"></i>{" "}
                </span>
              </div>
              <div>
                {boxItems.map((boxItem, index) => (
                  <div className="dropdownitems" key={index}>
                    <span>
                      <input
                        disabled
                        value={boxItem.response}
                        type="Text"
                        className="textopt"
                      />
                    </span>
                    <span onClick={() => boxRemoveItem(index)}>
                      <img
                        slot="end"
                        className="crossImg"
                        src={require("../../../../images/cross.png")}
                        alt=""
                      />
                    </span>
                  </div>
                ))}
                <br />
                <div className="dropdownitems1">
                  <span>
                    <input
                      type="text"
                      autoFocus
                      className="boxradiofield"
                      value={newBoxValue}
                      onChange={(e) => onChangeBoxval(e.target.value)}
                      placeholder="Enter your check box option here."
                    />
                  </span>
                  {errVar && (
                    <span className="errorCode">
                      Please enter unique options.
                    </span>
                  )}
                </div>
              </div>
              <br />
              <br />
              <span className="addbutton">
                <button onClick={boxAddItem} className="add" disabled={errVar}>
                  Add+
                </button>
              </span>
              <span className="savebutton">
                <button
                  onClick={boxSaveAndClose}
                  className="save"
                  disabled={boxItems.length < 1}
                >
                  Save
                </button>
              </span>
            </>
          )}

          {/* radio button */}
          {inputType == "radio" && (
            <>
              <div className="header">
                <span>Create Radio Button Options</span>
                <span className="app-closebutton" onClick={onClose}>
                  {" "}
                  <i className="fa-solid fa-xmark"></i>{" "}
                </span>
              </div>
              <div>
                {radioItems.map((radioItem, index) => (
                  <div className="dropdownitems" key={index}>
                    <span>
                      <input
                        value={radioItem.response}
                        type="text"
                        className="textopt"
                      />
                    </span>
                    <span onClick={() => radioRemoveItem(index)}>
                      <img
                        slot="end"
                        className="crossImg"
                        src={require("../../../../images/cross.png")}
                        alt=""
                      />
                    </span>
                  </div>
                ))}
                <br />
                <div className="dropdownitems1">
                  <span>
                    <input
                      type="text"
                      autoFocus
                      className="boxradiofield"
                      value={newRadioValue}
                      onChange={(e) => onChangeRadioval(e.target.value)}
                      placeholder="Enter your option here."
                    />
                  </span>
                  {errVar && (
                    <span className="errorCode">
                      Please enter a unique option.
                    </span>
                  )}
                </div>
              </div>
              <br />
              <br />
              <span className="addbutton">
                <button
                  onClick={radioAddItem}
                  className="add"
                  disabled={errVar}
                >
                  Add+
                </button>
              </span>
              <span className="savebutton">
                <button
                  onClick={radioSaveAndClose}
                  className="save"
                  disabled={radioItems.length < 1}
                >
                  Save
                </button>
              </span>
            </>
          )}

          <br />
          <br />
        </div>
      </div>
    </div>,
    document.getElementById("portals") as Element
  );
};

export default DropDownPopup;
