import React from "react";
import "./header.scss";

const Header = () => {
  return (
    <div className="heading_container">
      <div className="hmain">
        <img src={require("../../../images/ltimlogo.png")} alt="" />
        <div className="app_title">PBT Assesment</div>
        <div className="greet_user">
          <span>Welcome </span>
          <span>{sessionStorage.getItem("PBT_FIRSTNAME")}</span>
        </div>
      </div>
      <div className="underlines"></div>
    </div>
  );
};

export default Header;
