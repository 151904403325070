import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export const globalVariableSlice = createSlice({
    name: "globalVariable",
    initialState: {
        newAssessment: true,
        showSidebar: false
    },
    reducers: {
        updateNewAssessmentVariable: (state, action: PayloadAction<boolean>) => {
            state.newAssessment = action.payload;
            sessionStorage.setItem("newAssessment", JSON.stringify(action.payload))
        },
        updateShowSidebar: (state, action: PayloadAction<boolean>) => {
            state.showSidebar = action.payload;
        }
    }
})

export const { updateNewAssessmentVariable, updateShowSidebar } = globalVariableSlice.actions;
export default globalVariableSlice.reducer;